import { $post } from '@/api/http.js'
import tool from '@/util/tool.js'
import common from "@/api/common.js"
import router from '@/router'
import moment from 'moment'
import siteinfo from '@/util/global'

class Active{

    /**
     * 获取活动列表
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getActive(page,limit,extra,fn){
        let param = { page,limit }
        if( extra ){
            if( extra.status ) param.status = extra.status
        }
        common.getDataList("getEventListByStore",param).then(res=>fn(res))
    }

    /**
     * 获取活动详情
     * @param {number} id 
     * @param {Array} keys 需要的字段
     * @param {function} fn 
     */
    getActiveDetail(id,keys=null,fn){
        $post('getEventdetailsByStore',{id}).then(res =>{
            if( !keys){
                fn(res.data)
                return
            }
            let data = tool.filterForm(keys,res.data)
            fn(data)
        })
    }

    /**
     * 新增或编辑活动
     * @param {object} param 
     */
    addOrEditActive(param){
        if( !tool.returnMessage(param.title,'请输入活动名称')) return
        if( !tool.returnMessage(param.address,'请输入活动地址')) return
        if( !tool.returnMessage(param.max_number,'请输入活动人数')) return
        if( !tool.returnMessage(param.class_id,'请选择活动分类')) return
        if( !tool.returnMessage(param.activity_start_time,"请选择活动时间")) return

        param.content = JSON.stringify(param.content)
        if( !param.id ) delete param.id
        $post("createOrUpdateEventInfo",param).then(()=>{
            tool.message("保存成功")
            router.go(-1)
        })
    }

    /**
     * 删除活动
     * @param {number} id 
     * @param {function} fn 
     */
    deleteActive(id,fn){
        if( !tool.returnMessage(id,'请选择要删除的分类！')) return
        
        tool.confirm("确认删除活动吗？").then(()=>{
            $post('deleteEvent',{id}).then(res =>{
                tool.message("删除成功")
                fn(res.data)
            }).catch(() =>{})
        }).catch(()=>{})
    }

    /**
     * 获取活动分类信息
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getActiveType(page,limit,fn){
        let param = { page, limit };
        common.getDataList("EventClassList",param).then(res=>fn(res))
    }

    /**
     * 新增或编辑活动分类
     * @param {object} param 
     * @param {function} fn 
     */
    addOrEditActiveType(param,fn){
        if( !tool.returnMessage(param.name,"请填写分类名称")) return
        if( !param.id ) delete param.id
        $post("createOrEditEventClass",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 活动操作
     * @param {string} action is_put_away上下架 open_status开启状态
     * @param {number} id 
     * @param {number} value 
     * @param {function} fn 
     */
    handleActive(action,id,value,fn){
        let param ={ id,action,value }
        $post('changgeEvEnt',param).then(res=>{
            tool.message('操作成功')
            fn(res.data)
        })
    }

    /**
     * 活动审核
     * @param {number} id 
     * @param {number} value    2通过 3拒绝
     * @param {function} fn 
     */
    auditActive(id,value,fn){
        let title = `确认${value == 2 ?'通过':'拒绝'}该活动审核吗？`
        tool.confirm(title).then(()=>{
            $post('AuditEvent',{id,value}).then(res=>{
                tool.message('操作成功')
                fn(res.data)
            })	
        }).catch(()=>{})
    }

    /**
     * 删除报名信息
     * @param {number} id 
     * @param {function} fn 
     */
    deleteActiveFroms(id,fn){
        $post('deleteByFroms',{id}).then(res=>{
            tool.message('删除成功')
            fn(res.data)
        })
    }

    /**
     * 获取所有的报名信息
     * @param {number} id 
     * @param {function} fn 
     */
    getActiveForms(id,fn){
        $post('getAllForms',{rid:id}).then(res=>fn(res.data))
    }

    /**
     * 新增报名信息
     * @param {object}} param 
     * @param {function} fn 
     */
    addActiveForms(param,fn){
        if( !tool.returnMessage(param.name,"请输入内容")) return
        $post("createOrUpdateForms",param).then(res=>{
            tool.message("添加成功")
            fn(res.data)
        })
    }

    /**
     * 获取活动的票种信息
     * @param {number} id 
     * @param {function} fn 
     */
    getActiveTicket(id,fn){
        $post('getAllTickets',{ rid:id }).then(res=>fn(res.data))
    }

    /**
     * 新增或编辑票种信息
     * @param {object} param 
     * @param {function} fn 
     */
    addOrEditTicket(param,fn){
        if( param.regis_start_time ) param.regis_start_time = moment(param.regis_start_time).format("YYYY-MM-DD HH:mm:ss")
        if( param.regis_end_time ) param.regis_end_time = moment(param.regis_end_time).format("YYYY-MM-DD HH:mm:ss")
        if( param.active_start_time ) param.active_start_time = moment(param.activity_start_time).format("YYYY-MM-DD HH:mm:ss")
        if( param.active_end_time ) param.active_end_time = moment(param.active_end_time).format("YYYY-MM-DD HH:mm:ss")
        if( !param.id ) delete param.id

        $post('createOrUpdateTickets',param).then(res=>{
            tool.message('保存成功')
            fn(res.data)
        })
    }

    /**
     * 删除票种信息
     * @param {number} id 
     * @param {function} fn 
     */
    deleteTicket(id,fn){
        tool.confirm("确认删除票种信息吗？").then(res=>{
            $post('deleteByTickets',{id}).then(res=>{
                tool.message('删除成功');
                fn(res.data)
            })
        })
    }

    /**
     * 获取活动订单列表
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getActiveOrder(page,limit,extra,fn){
        let param = { page,limit}
        if( extra && extra.status ) param.status =extra.status
        common.getDataList("getEventOrderList",param).then(res=>fn(res))
    }

    /**
     * 活动订单审核
     * @param {object} param  param.type 1通过，2驳回
     * @param {function} fn 
     */
    auditActiveOrder(param,fn){
        tool.confirm(`确认${param.type == 1?'通过':'驳回'}该票的审核吗？`).then(()=>{
            if( param.type == 2 && !param.msg ){
                tool.message("请输入驳回原因","warning")
                return
            }
            $post('AuditEventOrder',param).then(res=>{
                tool.message('操作成功')
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 活动订单验票
     * @param {number} order_id 
     * @param {number} rid 
     * @param {function} fn 
     */
    checkActiveOrder(order_id,rid,fn){
        tool.confirm("确认现在进行验票吗？").then(()=>{
            $post('handEventwriteOff',{order_id,rid}).then(res=>{
                tool.message('操作成功')
                fn(res.data)
            })
        }).catch(()=>{})
    }
}
const activeModel = new Active()
export default activeModel